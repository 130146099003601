import env from '@/env.js';
import axios from 'axios';

export default {
    async post(endpoint, params) {
        return await sendApiRequest('post', endpoint, params);
    },

    async put(endpoint, params) {
        return await sendApiRequest('put', endpoint, params);
    },

    async get(endpoint) {
        return await sendApiRequest('get', endpoint);
    }
}

async function sendApiRequest(method, endpoint, data) {
    var url,
        response,
        result,
        host;

    try {
        host = env.dev ? 'http://127.0.0.1:3000' : 'https://checkout.cyphercoders.com';
        url = `${host}/${endpoint}`;
        data = data || {};
        data.source = data.source || 'classroom';

        switch (method) {
            case 'get':
                response = await axios.get(url);
                break;
            case 'post':
                response = await axios.post(url, data);
                break;
        }

        if (response) {
            result = response.data;
        }
    }
    catch (err) {
        // If connection failed, try again in a few seconds
        if (err.message.indexOf('Network Error') >= 0) {
            setTimeout(() => {
                sendApiRequest(method, endpoint, data);
            }, 5000);
        }
        else {
            throw new Error(`${method} request failed to ${url}: ${err.message}`, { cause: err })
        }
    }

    return result;
}