<template>
    <iframe class="survey" :src="filloutUrl"></iframe>
</template>

<script setup>

import { computed, onMounted } from "vue";

const emit = defineEmits(['submit']);

const props = defineProps({
    filloutId: String,
    studentUuid: String,
    lessonUuid: String
});

onMounted(() => {
    // Listen for form submitted message
    window.addEventListener("message", function (event) {
        var message;
        try {
            message = JSON.parse(event.data);
            if (
                message.channel === "form_channel" && message.state === "submitted"
            ) {

                emit('submit');
            }
        } catch (error) {
            // Ignore non-JSON messages
        }
    });
});

const filloutUrl = computed(() => {
    return `https://form.fillout.com/t/${props.filloutId}?student=${props.studentUuid}&lesson=${props.lessonUuid}`;
});

</script>

<style lang="scss" scoped>
.survey {
    width: 100%;
    flex: 1;
}
</style>
